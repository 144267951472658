<template>
  <v-container>
    <v-overlay :value="active" opacity="0.9">
      <div class="text-center">
        <div v-if="message" class="mb-3">{{ message }}</div>
        <v-progress-circular indeterminate size="75"></v-progress-circular>
      </div>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: "OverlayCustom",
  components: {},
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: null,
    },
  },
};
</script>
