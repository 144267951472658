<template>
  <v-container>
    <v-overlay :value="show" opacity="0.99">
      <div class="text-center">
        <div>
          <v-icon large>mdi-lan-disconnect</v-icon>
          {{ t("No internet connection!") }}
        </div>
      </div>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: "NoConnection",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
