import { forEach, isUndefined } from "lodash";
import { theApp } from "@/main";

export const mapErrors = (errorDetails) => {
  let errors = [];

  if (!isUndefined(errorDetails)) {
    forEach(errorDetails.errors, function (messages, field) {
      forEach(messages, function (message) {
        if (isUndefined(errors[field])) {
          errors[field] = [];
        }

        errors[field].push(theApp.t(message));
      });
    });
  }

  return errors;
};
